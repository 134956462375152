const getStatusLabel = (entry_status, moneyType) => {
  // Define a mapping of status codes to human-readable labels
  const statuses = {
    purchase: {
      money: 'Refill',
      bonus_money: 'Bonus Cash on Refill',
    },
    withdraw: {
      money: 'Withdraw',
      bonus_money: 'Bonus Cash Redemption',
    },
    bet: {
      money: 'Entry Deduction',
      bonus_money: 'Bonus Cash Entry Deduction',
    },
    bet_won: {
      money: 'Entry Won',
      bonus_money: 'Bonus Cash Redemption',
    },
    bet_reverse: {
      money: 'Entry Reversed',
      bonus_money: 'Bonus Cash Entry Reversed',
    },
    adjustment: {
      money: 'Adjustment For Failed Transaction',
      bonus_money: 'Adjustment For Failed Transaction',
    },
    purchase_failed: {
      money: 'Purchase Failed',
      bonus_money: 'Purchase Failed',
    },
    withdraw_reverse: {
      money: 'Withdraw Failed',
      bonus_money: 'Withdraw Failed',
    },
  };

  // Check if the passed status exists in the mapping
  if (statuses.hasOwnProperty(entry_status)) {
    const moneyStatuses = statuses[entry_status];

    // Check if the money type (cash/bonus) exists for the given status
    if (moneyStatuses.hasOwnProperty(moneyType)) {
      return moneyStatuses[moneyType];
    }
  }

  return 'Unknown Status'; // Default case if status or money type is not found
};

export default getStatusLabel;
