import React from 'react';
import { Button, Icon, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const UserBalanceViewBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const [user_balance, setUser_balance] = React.useState('');
  const [user_points, setUser_points] = React.useState('');

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignContent: 'flex-end',
          alignItems: [
            { minWidth: Breakpoints.Laptop, value: 'flex-end' },
            { minWidth: Breakpoints.Mobile, value: 'flex-end' },
          ],
          flexDirection: 'column',
          height: 60,
          width: '60%',
        },
        dimensions.width
      )}
    >
      <ThrillerXanoApi.FetchUserBalanceGET
        handlers={{
          onData: fetchData => {
            try {
              setUser_balance(fetchData?.balance + ' $');
              setUser_points(fetchData?.points + ' PT');
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchUserBalance }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* amount */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    minWidth: 200,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('BalanceMainP1InProgressScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderRightWidth: {
                        minWidth: Breakpoints.Tablet,
                        value: 10,
                      },
                      marginRight: { minWidth: Breakpoints.Laptop, value: 20 },
                    },
                    dimensions.width
                  )}
                >
                  {/* amount__bg */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Mobile, value: 'stretch' },
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                        ],
                        backgroundColor: palettes.App['Thriller Pink'],
                        borderRadius: 35,
                        flexDirection: [
                          { minWidth: Breakpoints.Laptop, value: 'row' },
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                        ],
                        height: 35,
                        justifyContent: [
                          { minWidth: Breakpoints.Mobile, value: 'center' },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'space-between',
                          },
                        ],
                        paddingBottom: 8,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App['TFA-White']}
                      name={'FontAwesome/money'}
                      size={20}
                      style={StyleSheet.applyWidth(
                        { marginRight: 5 },
                        dimensions.width
                      )}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['headling-19'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['headling-19'].style,
                          {
                            alignSelf: 'center',
                            fontFamily: 'Roboto_500Medium',
                            fontSize: 14,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'$' + fetchData?.balance}
                      {'\n'}
                    </Text>
                  </View>
                </Touchable>

                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 5 },
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('BalanceMainP1InProgressScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'Entypo/trophy'}
                    iconSize={16}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: palettes.App['TFA-Green'],
                          borderRadius: 35,
                          fontFamily: 'Roboto_500Medium',
                          letterSpacing: 0.5,
                          marginRight: 8,
                          minHeight: 35,
                          paddingBottom: 0,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={`${'$' + fetchData?.bonus_money}`}
                  />
                </View>
              </View>
            </>
          );
        }}
      </ThrillerXanoApi.FetchUserBalanceGET>
    </View>
  );
};

export default withTheme(UserBalanceViewBlock);
