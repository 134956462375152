import React from 'react';
import {
  Button,
  KeyboardAvoidingView,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const AccountDeletionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [error, setError] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const thrillerXanoRequestAccountDeletionPOST =
    ThrillerXanoApi.useRequestAccountDeletionPOST();

  return (
    <ScreenContainer
      hasBottomSafeArea={true}
      hasSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['TFA-ScreenBG'] },
        dimensions.width
      )}
    >
      {/* Content Avoiding View */}
      <KeyboardAvoidingView
        behavior={'padding'}
        enabled={true}
        keyboardVerticalOffset={0}
        style={StyleSheet.applyWidth({ flexGrow: 1 }, dimensions.width)}
      >
        {/* Header Wrapper */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexGrow: 1,
              flexShrink: 0,
              justifyContent: 'center',
              marginLeft: 18,
              marginRight: 18,
            },
            dimensions.width
          )}
        >
          {/* Screen Title */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['TFA-White'],
                fontFamily: 'Roboto_400Regular',
                fontSize: 25,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Request Account Deletion'}
          </Text>
          {/* Screen Description */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['TFA-White'],
                fontFamily: 'Roboto_400Regular',
                fontSize: 16,
                marginTop: 18,
                textAlign: 'center',
                typography: theme.typography.body1,
              },
              dimensions.width
            )}
          >
            {'Enter your password to confirm the account deletion request.'}
          </Text>
          {/* Confirmation Value Field */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newConfirmationValueFieldValue => {
              try {
                setTextInputValue(newConfirmationValueFieldValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            editable={true}
            placeholder={'Enter Password'}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors.border.brand,
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                borderWidth: 1,
                color: palettes.App['TFA-White'],
                fontFamily: 'Inter_400Regular',
                fontSize: 16,
                height: 44,
                marginTop: 40,
                paddingBottom: 8,
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 8,
              },
              dimensions.width
            )}
            value={textInputValue}
          />
          {/* Screen Description 2 */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['TFA-White'],
                fontFamily: 'Roboto_400Regular',
                fontSize: 12,
                marginTop: 18,
                textAlign: 'left',
                typography: theme.typography.body1,
              },
              dimensions.width
            )}
          >
            {
              'Please Note: It might take 24-48 Hours to complete deletion request.'
            }
          </Text>
          {/* error */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App.Peoplebit_Salmon_Red,
                fontFamily: 'Roboto_400Regular',
                fontSize: 18,
                marginTop: 20,
              }),
              dimensions.width
            )}
          >
            {error}
          </Text>
        </View>
        {/* Content Wrapper */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexGrow: 1,
              flexShrink: 0,
              justifyContent: 'flex-end',
              marginLeft: 18,
              marginRight: 18,
            },
            dimensions.width
          )}
        >
          {/* Confirm Account Button */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const resp = (
                    await thrillerXanoRequestAccountDeletionPOST.mutateAsync({
                      password: textInputValue,
                    })
                  )?.json;
                  if (extractErrorFromResponse(resp) === 'false') {
                    setError('');

                    showAlertUtil({
                      title: 'Success',
                      message: 'Request Placed Successfully!',
                      buttonText: undefined,
                    });

                    navigation.navigate('BottomTabNavigator', {
                      screen: 'MyProfileMainP1InProgressScreen',
                    });
                  } else {
                    setError(extractErrorFromResponse(resp));
                  }
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App.Peoplebit_Salmon_Red,
                borderRadius: 8,
                fontFamily: 'Roboto_700Bold',
                height: 44,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Submit Request'}
          />
          {/* Go Back */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('BottomTabNavigator', {
                  screen: 'MyProfileMainP1InProgressScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App.Studily_Primary,
                borderRadius: 8,
                fontFamily: 'Roboto_700Bold',
                height: 44,
                marginBottom: 10,
                marginTop: 10,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Go Back'}
          />
          {/* Disclaimer Text */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.light,
                fontFamily: 'Roboto_400Regular',
                marginBottom: 24,
                marginTop: 12,
                textAlign: 'center',
                typography: theme.typography.caption,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {
              'By Confirming in you agree to our Terms of Service, Privacy Policy and Cookie Policy. '
            }
          </Text>
        </View>
        {/* Footer Wrapper */}
        <View />
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

export default withTheme(AccountDeletionScreen);
